<template>
  <div class="order-page mx-3 mb-3 mt-5">
    <img
      height="1"
      width="1"
      style="display:none"
      :src="`https://www.facebook.com/tr?id=${fbPixel}&ev=PageView`"
    />
    <img
      height="1"
      width="1"
      style="display:none"
      :src="`https://www.facebook.com/tr?id=${fbPixel}&ev=ViewContent`"
    />
    <div class="header-order mt-2 d-flex justify-space-between pe-3">
      <div class="header-order-text d-flex">
        <!-- <div class="header-order-navigate mt-2 me-2">
          <v-icon class="" color="#EA7C69">
            {{ icons.mdiArrowLeft }}
          </v-icon>
        </div> -->
        <div>
          <v-skeleton-loader
            v-if="detailShop.name === ''"
            v-bind="attrs2"
            max-width="180"
            type="list-item"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="detailShop.address === ''"
            v-bind="attrs2"
            class="margin-skeleton"
            type="list-item"
          ></v-skeleton-loader>
          <span
            v-if="detailShop.address !== ''"
            class="header-title"
          >{{ detailShop.name }}</span>
          <p
            v-if="detailShop.address !== ''"
            class="header-desc"
          >
            {{ detailShop.address }}
          </p>
        </div>
      </div>
      <!-- <div class="header-order-notif notification text-end justify-end mt-1">
        <v-icon class="" color="white">
          {{ icons.mdiScriptTextOutline }}
        </v-icon>
        <span v-if="orderCart.length !== 0" class="badge">{{ orderCart.length }}</span>
      </div> -->
    </div>
    <v-divider />
    <div class="tools mt-3 d-flex">
      <div class="tools-search">
        <v-text-field
          v-model="input"
          outlined
          label="Search"
          type="text"
          color="#EA7C69"
        >
          <template v-slot:append>
            <v-fade-transition leave-absolute>
              <v-icon
                color="white"
                @click="filterSearch()"
              >
                {{ icons.mdiMagnify }}
              </v-icon>
            </v-fade-transition>
          </template>
        </v-text-field>
      </div>
      <!-- <div class="tools-view text-center ms-3">
        <div class="btn-tools-view">
          <v-icon large color="white">
            {{ icons.mdiViewListOutline }}
          </v-icon>
        </div>
      </div> -->
    </div>
    <v-carousel
      v-model="model"
      :show-arrows="false"
      hide-delimiter-background
      height="160"
      :hide-delimiters="true"
    >
      <v-skeleton-loader
        v-if="banners.length === 0"
        v-bind="attrs"
        type="image"
      ></v-skeleton-loader>
      <div v-if="banners.length !== 0">
        <v-carousel-item
          v-for="(banner, indexBanner2) in banners"
          :key="indexBanner2"
        >
          <v-sheet
            height="100%"
            tile
          >
            <v-row
              class="fill-height"
              align="end"
              justify="center"
            >
              <div class="">
                <img
                  :src="banner.Image !== null ? banner.Image : 'https://i.stack.imgur.com/81VAF.jpg'"
                  alt="banner"
                  class="img-banner"
                />
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </div>
    </v-carousel>
    <div class="caroulsel-navigate">
      <div
        v-if="banners.length === 0"
        class="d-flex"
      >
        <v-skeleton-loader
          v-for="index in 3"
          :key="index"
          class="me-4"
          v-bind="attrs"
          type="button"
          max-width="8"
          max-height="8"
        ></v-skeleton-loader>
      </div>
      <div
        v-if="banners.length !== 0"
        class="d-flex"
      >
        <div
          v-for="(banner, indexBanner) in banners"
          :key="indexBanner"
        >
          <div
            :class="`caroulsel-navigate-button mx-2  ${indexBanner === model ? 'caroulsel-navigate-button-active' : ''}`"
            @click="caroulselButton(indexBanner)"
          ></div>
        </div>
      </div>
      <!-- <div>
        <div :class="`caroulsel-navigate-button mx-1 `"></div>
      </div> -->
    </div>
    <v-divider class="" />
    <div
      v-if="typeItem.length === 0"
      class="scrollmenu mt-3"
    >
      <div class="d-flex">
        <v-skeleton-loader
          v-for="index2 in 2"
          :key="index2"
          class="me-4"
          v-bind="attrs"
          type="chip"
        ></v-skeleton-loader>
      </div>
    </div>
    <div
      v-if="typeItem.length !== 0"
      class="scrollmenu mt-3"
    >
      <div
        :class="filterByType !== 'all items' ? 'filter-tools' : 'filter-tools-active'"
        @click="clearFilter"
      >
        All Items
      </div>
      <div
        v-for="type in typeItem"
        :key="type.id"
        :class="filterByType === type.type ? 'filter-tools-active' : 'filter-tools'"
        @click="filterType(type.type)"
      >
        {{ type.type }}
      </div>
    </div>
    <v-divider class="mt-3 mb-3" />
    <div :class="orderCart.length === 0 ? 'order-list-footer-hide' : 'order-list'">
      <!-- <div>
        <div class="order-list-menu-active pt-2 pb-2" @click="openDetail()">
          <div class="order-list-item-menu d-flex ps-3 justify-space-between pe-3">
            <div class="d-flex">
              <img src="https://imgur.com/EnTcU9F.png" alt="" />
              <div class="order-list-desc ms-3">
                <span class="order-list-desc-title">Food 1</span>
                <p class="order-list-desc-text">main dish</p>
              </div>
            </div>

            <div class="order-list-price pt-2">
              <span class="order-list-desc-price">Rp. 275.000.000</span>
            </div>
          </div>
        </div>
        <v-divider class="mt-2 mb-2" />
      </div> -->
      <div v-if="orderList.length === 0">
        <v-skeleton-loader
          v-for="index3 in 5"
          :key="index3"
          v-bind="attrs"
          type="list-item-avatar"
        ></v-skeleton-loader>
      </div>

      <div v-if="orderList.length !== 0">
        <div
          v-for="(item, indexExcel) in orderList"
          :key="`${indexExcel}-exceldata`"
          :class="item['Out Of Stock'] === 'YES' ? 'order-list-menu' : ''"
        >
          <div
            :class="'pt-2 pb-2'"
            @click="openDetail(item.id, item)"
          >
            <div class="order-list-item-menu d-flex ps-3 justify-space-between pe-3">
              <div class="d-flex">
                <img
                  :src="item.Photo !== null ? item.Photo : 'https://i.stack.imgur.com/81VAF.jpg'"
                  alt=""
                  style="object-fit: cover;"
                />
                <div class="order-list-desc ms-3">
                  <span class="order-list-desc-title">{{ item.Name }}</span>
                  <p class="order-list-desc-text">
                    {{ item.Category }}
                  </p>
                  <p
                    v-if="item['Out of Stock'] === 'YES'"
                    class="order-list-desc-title"
                    style="margin-top: -10px; margin-bottom: -5px"
                  >
                    Out Of Stock
                  </p>
                </div>
              </div>

              <div class="order-list-price pt-2">
                <span
                  class="order-list-desc-price"
                >Rp. {{ new Intl.NumberFormat(['ban', 'id']).format(item['Base Price']) }}</span>
              </div>
            </div>
          </div>
          <v-divider class="mt-2 mb-2 order-list-menu" />
        </div>
      </div>
    </div>
    <div :class="orderCart.length === 0 ? 'hide-footer' : 'footer'">
      <div
        class="btn-footer text-center"
        @click="goToOrderCart"
      >
        <span class="footer-text">View Order Lists ({{ orderCart.length }})</span>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>


<script>
import LoadScript from 'vue-plugin-load-script'
import eFormMixin from '@/mixins/eFormMixin'
import { mdiScriptTextOutline, mdiArrowLeft, mdiMagnify, mdiViewListOutline, mdiViewGridOutline } from '@mdi/js'
import _ from 'lodash'
import Vue from 'vue'

Vue.use(LoadScript)
export default {
  setup() {
    return {
      icons: {
        mdiScriptTextOutline,
        mdiArrowLeft,
        mdiMagnify,
        mdiViewListOutline,
        mdiViewGridOutline,
      },
    }
  },
  mixins: [eFormMixin],
  data: () => ({
    fbPixel: '',
    filterByType: 'all items',
    filterBySearch: '',
    orderDummy: [],
    orderList: [],
    orderCart: [],
    detailShop: {
      name: '',
      address: '',
    },
    model: 0,
    colors: ['primary', 'secondary', 'yellow darken-2', 'red', 'orange'],
    timeout: null,
    banners: [],
    attrs: {
      class: 'mb-6',
      boilerplate: true,
      elevation: 2,
    },
    attrs2: {},
  }),
  computed: {
    input: {
      get() {
        return this.filterBySearch
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.filterBySearch = val
          this.filterSearch()
        }, 1500)
      },
    },
    typeItem() {
      const types = JSON.parse(JSON.stringify(this.orderDummy))
      const arr = []
      types.forEach(el => {
        arr.push(el.Category)
      })
      const arr2 = _.uniq(arr)
      const itemType = arr2.map((el, i) => {
        return { id: i + 1, type: el }
      })

      return itemType
    },
  },
  async mounted() {
    this.$vuetify.theme.isDark = true

    const data = await this.$store.dispatch('eOrdering/getSlugEordering', { slug: this.$route.params.name })

    // console.log(data, `INI DATA`)
    this.detailShop = {
      name: data?.data?.shopData?.data?.name,
      address: `${data?.data?.shopData?.data?.address?.detail}${
        data?.data?.shopData?.data?.address?.city.type ? `, ${data?.data?.shopData?.data?.address?.city.type} ` : ''
      } ${
        data?.data?.shopData?.data?.address?.city.city_name
          ? `${data?.data?.shopData?.data?.address?.city.city_name},`
          : ''
      } ${
        data?.data?.shopData?.data?.address?.subdistrict.subdistrict_name
          ? `Kecamatan ${data?.data?.shopData?.data?.address?.subdistrict.subdistrict_name},`
          : ''
      } ${
        data?.data?.shopData?.data?.address?.city.province ? data?.data?.shopData?.data?.address?.city.province : ''
      }`,
    }
    const urlLink = data.data.shopData.data.urlGenerate

    if (urlLink.length !== 0) {
      const dataSlug = urlLink.find(el => {
        return this.$route.params.name === el.slug
      })

      // console.log(dataSlug, 'snioansi')
      if (dataSlug.hasOwnProperty('facebookId')) {
        if (dataSlug.facebookId) {
          this.fbPixel = dataSlug.facebookId
          this.$store.dispatch('eOrdering/setFbPixelSavetoVueX', dataSlug.facebookId)

          // this.savePixel = dataSlug.facebookId
        } else {
          this.$store.dispatch('eOrdering/setFbPixelSavetoVueX', '')

          // this.savePixel = dataSlug.facebookId
        }
      } else {
        this.$store.dispatch('eOrdering/setFbPixelSavetoVueX', '')

        // this.savePixel = dataSlug.facebookId
      }
      if (dataSlug.hasOwnProperty('googleTag')) {
        if (dataSlug.googleTag) {
          await this.$loadScript(`https://www.googletagmanager.com/gtag/js?id=${dataSlug.googleTag}`)
            .then(() => {
              window.dataLayer = window.dataLayer || []
              function gtag() {
                window.dataLayer.push(arguments)
              }
              gtag('js', new Date())
              gtag('config', dataSlug.googleTag)
            })
            .catch(() => {
              // Failed to fetch script
            })
        } else {
          this.$store.dispatch('eOrdering/setFbPixelSavetoVueX', '')
        }
      } else {
        this.$store.dispatch('eOrdering/setFbPixelSavetoVueX', '')
      }
    }

    this.banners = data.data.airtable.value.banners.filter(el => {
      const startDate = new Date(el['Start Date']).getTime() / 1000
      const startDateToday = new Date().getTime() / 1000
      const expiredDate = new Date(el['Expired Date']).getTime() / 1000
      if (el.Status === 'Active' && startDateToday > startDate && startDateToday < expiredDate) {
        return el
      }
    })

    // console.log(
    //   this.banners,
    //   `INI BANNERS
    // `,
    // )

    if (data.status) {
      if (!localStorage.getItem(this.$route.params.name)) {
        localStorage.setItem(this.$route.params.name, JSON.stringify(this.orderCart))
      } else {
        this.orderCart = JSON.parse(localStorage.getItem(this.$route.params.name))

        // console.log(this.orderCart)
      }
    }
    data.data.airtable.value.products.forEach(el => {
      // console.log(el, `INI EL`)
      if (el['Variant Of'] === null) {
        this.orderDummy.push(el)
      }
    })

    // console.log(this.orderDummy, `INI DUMMY`)

    // this.orderDummy = data.data.airtable.value.products.filter(el => Object.keys(el.fields).length !== 1)

    this.orderList = JSON.parse(JSON.stringify(this.orderDummy))

    // console.log(this.orderList, 'lllllllllllll')
  },
  methods: {
    filterType(type) {
      if (type !== '') {
        this.filterByType = type
        this.filterBySearch = ''
        this.orderList = JSON.parse(JSON.stringify(this.orderDummy))
        const arr = this.orderDummy.filter(el => {
          return el.Category === type
        })
        this.orderList = arr
      } else {
        this.filterBySearch = ''
        this.filterByType = 'all items'
      }
    },
    clearFilter() {
      this.filterByType = 'all items'
      this.orderList = JSON.parse(JSON.stringify(this.orderDummy))
    },
    filterSearch() {
      this.filterByType = 'all items'
      if (this.filterBySearch === '') {
        this.orderList = JSON.parse(JSON.stringify(this.orderDummy))
      } else {
        const arr = this.orderDummy.filter(el => {
          const nameData = el.Name.toLowerCase()

          return nameData.includes(this.filterBySearch.toLowerCase())
        })
        this.orderList = arr
      }
    },
    goToOrderCart() {
      this.$router.push({ name: 'order-checkout', params: { id: '1', name: this.$route.params.name } })
    },
    caroulselButton(id) {
      this.model = id
    },
    openDetail(itemId, item) {
      // console.log(itemId, item, `INI ITEM`)
      this.$store.dispatch('eOrdering/setDataSaveProductToVueX', item)

      if (item['Out Of Stock'] === 'NO') {
        this.$router.push({ name: 'order-detail', params: { id: itemId, name: this.$route.params.name } })
      }
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.order-page {
  font-family: 'Poppins', sans-serif;
}
.header-order {
  color: white;
}

.header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
}
.header-desc {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.tools {
  height: 68px;
}
.tools-search {
  width: 100%;
}
.tools-view {
  width: 15%;
}
.btn-tools-view {
  background: #ea7c69;
  padding: 8px;
  border-radius: 8px;
}
.btn-tools-grid {
  background: #3b5162;
  padding: 8px;
  border-radius: 8px;
}
div.scrollmenu {
  font-family: 'Barlow', sans-serif;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none /* IE and Edge */ !important;
  scrollbar-width: none /* Firefox */ !important;
}

div.scrollmenu::-webkit-scrollbar {
  display: none;
}

div.scrollmenu .filter-tools-active {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 8px 30px;
  background: #ea7c69;
  border-radius: 20px;
}

div.scrollmenu .filter-tools {
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  color: #ea7c69;
  text-align: center;
  text-decoration: none;
  padding: 8px 30px;
  border-radius: 20px;
  border: 1px solid rgba(231, 227, 252, 0.14);
}
.order-list {
  height: 38vh;
  overflow: auto;
}
.order-list-footer-hide {
  height: 48vh;
  overflow: auto;
}

.order-list-menu .order-list-desc-title {
  color: #5f5f5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
}
.order-list-menu .order-list-desc-text {
  color: #5f5f5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
}
.order-list-menu .order-list-desc-price {
  color: #5f5f5f;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
}
.order-list-item-menu img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.order-list-desc-title {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-text {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
}
.order-list-desc-price {
  font-family: 'Open Sans', sans-serif;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 140%;
}
.footer {
  padding: 12px 8px 8px 8px;
}
.btn-footer {
  color: white;
  padding: 12px;
  border-radius: 10px;
  background-color: #ea7c69;
}
.hide-footer {
  display: none;
}

.notification .badge {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 100px;
  background: #ea7c69;
  color: white;
}
.caroulsel-navigate-button {
  width: 8px;
  background-color: #c4c4c4;
  /* border: 2px solid #ea7c69; */
  height: 8px;
  border-radius: 20px;
}

.caroulsel-navigate-button-active {
  background-color: #ea7c69;
}
.img-banner {
  object-fit: cover;
  width: 340px;
  height: 135px;
}
.caroulsel-navigate {
  margin-top: -5px;
  margin-bottom: 8px;
}
.margin-skeleton {
  margin-top: -10px;
}
</style>
